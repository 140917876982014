import { render, staticRenderFns } from "./contact.vue?vue&type=template&id=104b8118&scoped=true&"
import script from "./contact.vue?vue&type=script&lang=js&"
export * from "./contact.vue?vue&type=script&lang=js&"
import style0 from "./contact.vue?vue&type=style&index=0&id=104b8118&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "104b8118",
  null
  
)

export default component.exports